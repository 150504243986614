import MenuContext from '@context/MenuContext';
import useVisualViewportHeight from '@hooks/useVisualViewportHeight';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Link from 'next/link';
import React, { useContext, useEffect, useRef } from 'react';

import useStyles from './MobileMenu.style';

const MobileMenu: React.FC = () => {
  const menuContext = useContext<MenuContext>(MenuContext);
  const { classes, theme } = useStyles({ open: menuContext.open });
  const { md } = useBreakpoints();
  const viewportHeight = useVisualViewportHeight();
  const scrollRef = useRef();

  useEffect(() => {
    if (menuContext.open) {
      disableBodyScroll(scrollRef.current);
    } else {
      enableBodyScroll(scrollRef.current);
    }

    () => clearAllBodyScrollLocks();
  }, [menuContext.open, scrollRef]);

  let height = 'auto';

  if (!md) {
    if (viewportHeight) {
      height = `calc(${viewportHeight} - 80px)`;
    } else {
      height = 'calc(100vh - 80px)';
    }
  }

  return (
    <div
      className={classes.perspectiveWrapper}
      style={{
        height
      }}
    >
      <div
        ref={scrollRef}
        className={classes.wrapper}
      >
        <div className={classes.screen}>
          <div style={{ flex: '1 1 100%' }}>
            <Typography
              variant="h2"
              component="div"
              style={{ margin: theme.spacing(5, 0) }}
            >
              <MuiLink
                href="/home-staging"
              >
                For Agents
              </MuiLink>
              
            </Typography>
            <Typography
              variant="h3"
              component="div"
              style={{ margin: theme.spacing(5, 0) }}
            >
              <MuiLink
                href="/home-staging"
              >
                Staging
              </MuiLink>
              
            </Typography>
            <Typography
              variant="h3"
              component="div"
              style={{ margin: theme.spacing(5, 0) }}
            >
              <MuiLink
                href="/cleaning"
              >
                Cleaning
              </MuiLink>
              
            </Typography>
            <Typography
              variant="h3"
              component="div"
              style={{ margin: theme.spacing(5, 0) }}
            >
              <MuiLink
                href="/moving"
              >
                Moving
              </MuiLink>
              
            </Typography>
            <Typography
              variant="h3"
              component="div"
              style={{ margin: theme.spacing(5, 0) }}
            >
              <MuiLink
                href="/photos"
              >
                Photography
              </MuiLink>
              
            </Typography>

            <Typography
              variant="h2"
              component="div"
              style={{ margin: theme.spacing(5, 0) }}
            >
              <MuiLink
                href="/home-buyer"
              >
                For Homebuyers
              </MuiLink>
            </Typography>

            <Typography
              variant="h2"
              component="div"
              style={{ margin: theme.spacing(5, 0) }}
            >
              <MuiLink
                href="/affirm"
              >
                Pay Later Program
              </MuiLink>
            </Typography>
          </div>

          <div>
            <Typography
              variant="h6Alt"
              component="div"
              style={{ margin: theme.spacing(5, 0, 5) }}
            >
              <Link
                href="/my/account"
                style={{ color: theme.palette.common.black }}
              >
                
                Account
                
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
